import React, { useState } from "react"

const defaultState = {
  handleCloseDropdown: () => {},
  handleOpenDropdown: () => {},
  setCurrentLanguage: () => {},
  currentLanguage: "pl",
  showDropdown: false,
}
const LanguageContext = React.createContext(defaultState)

function LanguageProvider({ children }) {
  const [showDropdown, setShowDropdown] = useState(false)
  const [currentLanguage, setCurrentLanguage] = useState("pl")
  const handleOpenDropdown = () => {
    console.log("open")
    setShowDropdown(true)
  }
  const handleCloseDropdown = () => {
    setShowDropdown(false)
  }

  return (
    <>
        <LanguageContext.Provider
          value={{
            handleCloseDropdown,
            handleOpenDropdown,
            setCurrentLanguage,
            currentLanguage: currentLanguage,
            showDropdown: showDropdown,
          }}
        >
          {children}
        </LanguageContext.Provider>
    </>
  )
}

export default LanguageContext

export { LanguageProvider }
