import React, { useState } from 'react';
const defaultState = {
  activeTab: false,
  toggleActiveTab: () => {
  },
}
const EstateNavActiveTabContext = React.createContext(defaultState)

const EstateNavActiveTabProvider = ({children}) => {
  const [activeTab, setActiveTab] = useState(false)
  const toggleActiveTab = (incomingActiveTab) => {
    // console.log(incomingActiveTab);
    setActiveTab(incomingActiveTab)
  }

  return (
    <EstateNavActiveTabContext.Provider
      value={{
        toggleActiveTab: toggleActiveTab,
        activeTab: activeTab
      }}
    >
      {console.log(activeTab)}
      {children}
    </EstateNavActiveTabContext.Provider>
  )
}

export default EstateNavActiveTabContext;

export { EstateNavActiveTabProvider }