import "./src/styles/global.css"
import React from "react"
import "bootstrap/dist/css/bootstrap.css"
import { LanguageProvider } from "./src/context/LanguageContext"
import { EstateNavActiveTabProvider } from "./src/context/EstateNavActiveTabContext"

export const wrapRootElement = ({ element }) => (
  <EstateNavActiveTabProvider>
    <LanguageProvider>{element}</LanguageProvider>
  </EstateNavActiveTabProvider>
)

export const shouldUpdateScroll = () => {
  // const currentPosition = getSavedScrollPosition(location)
  window.scrollTo(0, 0)
  return false
}
// export const onInitialClientRender = () => {
//   const showContent = () => {
//     document.getElementsByClassName('spinner')[0].style.display = 'none';
//   }
//     window.onload = showContent;
// }
